<template>
    <div>
        <window-header></window-header>
        <section>
            <article style="height:380px; width:400px">
                <template v-if="formData.secret_2fa">
                    <ol>
                        <li>Abre la aplicación de doble autenticación (por ejemplo, Google Authenticator).</li>
                        <li>Escanea el siguiente código QR:
                            <img :src="qr" style="width: 200px; display: block;">
                            o introduce manualmente la siguiente clave:
                            <code style="display: block">{{ formData.secret_2fa }}</code>
                        </li>
                        <li>Pulsa en el siguiente botón:
                            <button class="button button-fill" style="display: block; margin-top: 10px;"
                                @click="saveItem(); $emit('close')">
                                <fa-icon icon="check" /> Aceptar
                            </button>
                        </li>
                    </ol>
                </template>
            </article>
        </section>
    </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
export default {
    mixins: [WindowMixin, rFormMixin],
    data: function () {
        return {
            title: "Configurar 2FA",
            dbAdapter: "usuario",
            primary: "id",
            fields: ["secret_2fa"],
            defaultMode: 'edit',
            initialMode: 'edit',
            initialId: this.itemId,
            qr: null
        };
    },
    computed: {
    },
    methods: {
    },
    mounted() {
        var self = this;
        window.DB.action("usuario", "generar2FAQR").then(res => {
            self.formData.secret_2fa = res.secret
            self.qr = res.qr
        })
    }
};
</script>
<style></style>